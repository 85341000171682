@font-face {
  font-family: "Inter UI";
  font-display: block;
  src: url("/fonts/Inter/Inter-Regular.otf"),
    url("/fonts/Inter/Inter-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "Inter UI Bold";
  font-display: block;
  src: url("/fonts/Inter/Inter-Bold.otf"),
    url("/fonts/Inter/Inter-Bold.ttf") format("truetype");
}

@font-face {
  font-family: "Inter UI Medium";
  font-display: block;
  src: url("/fonts/Inter/Inter-Medium.woff") format("woff"),
    url("/fonts/Inter/Inter-Medium.woff2") format("woff2");
}

@font-face {
  font-family: "Larsseit";
  font-display: block;
  src: url("/fonts/Larsseit/Type Dynamic - Larsseit-Medium.otf"),
    url("/fonts/Larsseit/Type Dynamic - Larsseit-Medium.ttf") format("truetype");
}

@font-face {
  font-family: "Larsseit Bold";
  font-display: block;
  src: url("/fonts/Larsseit/Type Dynamic - Larsseit Bold.otf"),
    url("/fonts/Larsseit/Type Dynamic - Larsseit Bold.ttf") format("truetype");
}

@font-face {
  font-family: "Larsseit Regular";
  font-display: block;
  src: url("/fonts/Larsseit/Type Dynamic - Larsseit-Regular.otf"),
    url("/fonts/Larsseit/Type Dynamic - Larsseit-Regular.ttf")
      format("truetype");
}

@font-face {
  font-family: "Larsseit Light";
  font-display: block;
  src: url("/fonts/Larsseit/Type Dynamic - Larsseit-Light.otf"),
    url("/fonts/Larsseit/Type Dynamic - Larsseit-Light.ttf") format("truetype");
}

@font-face {
  font-family: "Roboto";
  font-display: block;
  src: url("/fonts/Roboto/Roboto-Regular.otf"),
    url("/fonts/Roboto/Roboto-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "Roboto Bold";
  font-display: block;
  src: url("/fonts/Roboto/Roboto-Bold.otf"),
    url("/fonts/Roboto/Roboto-Bold.ttf") format("truetype");
}

@font-face {
  font-family: "Roboto Light";
  font-display: block;
  src: url("/fonts/Roboto/Roboto-Light.otf"),
    url("/fonts/Roboto/Roboto-Light.ttf") format("truetype");
}

@font-face {
  font-family: "Eina 01 Bold";
  font-display: block;
  src: url("/fonts/Eina01/Eina01-Bold.otf"),
    url("/fonts/Eina01/Eina01-Bold.ttf") format("truetype");
}

@font-face {
  font-family: "CircularXXWeb-Regular";
  font-display: normal;
  src: url("/fonts/circularXXWeb/CircularXXWeb-Regular.woff"),
    url("/fonts/circularXXWeb/CircularXXWeb-Regular.woff2");
}

@font-face {
  font-family: "CircularXXWeb-Bold";
  font-display: normal;
  src: url("/fonts/circularXXWeb/CircularXXWeb-Bold.woff"),
    url("/fonts/circularXXWeb/CircularXXWeb-Bold.woff2");
}

@font-face {
  font-family: "CircularXXWeb-Medium";
  font-display: normal;
  src: url("/fonts/circularXXWeb/CircularXXWeb-Medium.woff"),
    url("/fonts/circularXXWeb/CircularXXWeb-Medium'.woff2");
}

@font-face {
  font-family: "Lexend";
  font-display: normal;
  src: url("/fonts/Lexend/Lexend-Regular.woff"),
    url("/fonts/Lexend/Lexend-Regular.woff2");
}

@font-face {
  font-family: "Lexend-Bold";
  font-display: normal;
  src: url("/fonts/Lexend/Lexend-Bold.woff"),
    url("/fonts/Lexend/Lexend-Bold.woff2");
}

@font-face {
  font-family: "Lexend-Medium";
  font-display: normal;
  src: url("/fonts/Lexend/Lexend-Medium.woff"),
    url("/fonts/Lexend/Lexend-Medium'.woff2");
}

@font-face {
  font-family: "Lexend-SemiBold";
  font-display: normal;
  src: url("/fonts/Lexend/Lexend-SemiBold.woff"),
    url("/fonts/Lexend/Lexend-SemiBold'.woff2");
}
